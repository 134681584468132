import { getSignatures } from "../commonDynmicForms/signature";
import { FillableBy } from "./form_enums";
import { evaluationCheckList } from "../commonDynmicForms/evaluation_checklist";
import { table_fields} from "../commonDynmicForms/table_fields"
import { constants } from "../constants";


export default {
    fillable_by:FillableBy.Both,
    title:
        "Clinical Competency Checklist for Terumo System 1 Heart-Lung Machine",
    sections: [
        // {...employeeinfo},
        {
            title: "Device Setup",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs visual inspection of the machine for cleanliness and physical integrity.",
                "Verifies proper connection and functionality of power and backup battery systems.",
                "Assembles and installs components (e.g., oxygenator, reservoirs, circuits) as per protocol.",
                "Ensures the system is correctly primed with no air bubbles in the circuit.",
                "Configures and calibrates monitoring equipment (e.g., arterial and venous pressures)."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Operation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Performs system start-up and verifies all self-checks are completed successfully.",
                "Adjusts settings for flow, pressure, and temperature per procedural requirements.",
                "Monitors and maintains optimal system performance during procedures.",
                "Demonstrates effective use of touch screen and user interface controls.",
                "Recognizes and responds to system alerts and alarms appropriately."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Safety and Infection Control",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Ensures all blood-contacting components are sterile and appropriately installed.",
                "Maintains compliance with manufacturer’s guidelines for infection prevention.",
                "Documents all cleaning and disinfection activities post-procedure.",
                "Follows safety protocols to minimize air embolism and cross-contamination risks.",
                "Maintains situational awareness to ensure patient and staff safety during use."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Troubleshooting and Emergency Procedures",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Identifies and interprets system errors and alarm codes.",
                "Resolves common issues such as air detection, low flow, or high pressure alarms.",
                "Implements manual bypass or alternate methods in case of system failure.",
                "Effectively communicates system issues to the surgical team and technical support.",
                "Follows emergency protocols for device malfunction or patient safety concerns."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {
            title: "Documentation",
            type: "table",
            heads: constants.tableHeads,
            rows: [
                "Records all pre-use checks and initial calibrations.",
                "Logs real-time changes and adjustments made during the procedure.",
                "Documents any equipment malfunctions or deviations for quality review.",
                "Completes post-use maintenance and component replacement logs.",
                "Maintains thorough and accurate records for regulatory compliance and audit purposes."
            ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
        },
        {...evaluationCheckList},
        {
            title: "Signatures",
            type: "signature",
            fields: getSignatures(FillableBy.Both)
        },
    ],
}